import React, { useEffect, useState } from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import { useForm } from "../../../hooks/useForm/UseForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTodayDate,
  getCurrentDay,
  getCurrentMonth,
  getCurrentYear,
  addDays,
  isEmpty,
  formatNumber,
  mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric
} from "../../../helper/utils";
import { toast } from "react-toastify";
import { GENERIC_SUCCESS_TOAST_MESSAGE,TIPO_CESE_IDS_MOTIVO,TIPO_CESE_RENUNCIA  } from "../../../constants/hardCodedConstants";
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../../consumers/httpRequiestsUtils";
import SelectInput from "../../../components/Select/SelectInput";
import { mostrarMensajesDeValidacionEnCadaInput } from "../../../validations/FormValidatorUtils";
import { useGetRequest } from "../../../hooks/useGetRequest/useGetRequest";
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";
import { buscarMisColaboradoresPorCriterioAPI, crearCeseAPI, getEditInfoCesesAPi, getMotivosRenunciaAPI,getTiposCeseAPI, guardarEditarCeseAPi } from "../../../consumers/backendApisUrls";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import { AlertErrorMaxFileSizeCeses } from "../alerts/AlertErrorMaxFileSizeCeses";
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useDescargarArchivoDeS3 } from '../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import InputTextArea from '../../../components/modalComponents/InputTextArea';

const EditarCeseModal = ({ 
  showModal, 
  manageSearch, 
  idCese, 
  showConfirmarEditarCeseModal, 
  confirmarEdit,
  setDatosConfirmar
}) => {
  const [subiendoArchivoSustento, setSubiendoArchivoSustento] = useState(false);
  const UPDATE_FILE_INPUT_ID = "addCeseModal-sustento";
  const re = /(?:\.([^.]+))?$/;
  const sectionName = "EditarCeseModal";
  const [rawData, setRawData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);

  const [s3UploadFileResponseSustento, setS3UploadFileResponseSustento] = useState({});

  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [colaboradoresFiltradosOptions, setColaboradoresFiltradosOptions] = useState([]);
  const [jsonSelectedColaboradorValue, setJsonSelectedColaboradorValue] = useState({});
  const [cargandoColaboradores, setCargandoColaboradores] = useState(false);
  const [selectInputColaboresPlaceholder, setSelectInputColaboresPlaceholder] = useState("");
  const [currentYear] = useState(getCurrentYear());
  const [currentMonth] = useState(getCurrentMonth());
  const [currentDay] = useState(getCurrentDay());

  const [executeGetRequest] = useGetRequest();
  const [executePostRequest] = usePostRequest();
  const [manageGetRequest] = useManageGetRequest();

  const [motivosDeRenunciaFiltradosOptions, setMotivosDeRenunciaFiltradosOptions] = useState([]);
  const [jsonSelectedMotivoRenunciaValue, setJsonSelectedMotivoRenunciaValue] = useState({});

  const [tiposDeCeseFiltradosOptions, setTiposDeCeseFiltradosOptions] = useState([]);
  const [jsonSelectedTiposCeseValue, setJsonSelectedTiposCeseValue] = useState({});

  const [jsonTextAreaOtros,setJsonTextAreaOtros] = useState("");
  const [showTextArea,setShowTextArea] = useState(false);

  const [downloadDataMail, setDownloadDataMail] = useState(null);

  const [descargarArchivo] = useDescargarArchivoDeS3();
  const [submitForm] = useSubmitForm();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(()=>{
    if(jsonSelectedMotivoRenunciaValue?.label != "Otros")
      setShowTextArea(false);
    else setShowTextArea(true);
  },[jsonSelectedMotivoRenunciaValue])

  const successSubmitCallback = async () => {
    showModal();
    await manageSearch();
  }

  const submitModalRegistrarCeseProxy = async () => {
    let descripcionMotivoRenunciaPayLoad = jsonTextAreaOtros;
    if(jsonSelectedMotivoRenunciaValue?.label==="Otros"){
      if(jsonTextAreaOtros==='') descripcionMotivoRenunciaPayLoad = 'No especifica'
    }
    else descripcionMotivoRenunciaPayLoad = null;

    const today = new Date()
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const fechaCese = new Date(formValues["FechaCese"]);
    let payload = {
      "IdCese": idCese,
      "IdTipoCese": jsonSelectedTiposCeseValue["value"],
      "IdMotivoRenuncia": TIPO_CESE_IDS_MOTIVO.includes(jsonSelectedTiposCeseValue.IdEntity)? jsonSelectedMotivoRenunciaValue?.value:null,
      "DescripcionMotivoRenuncia": descripcionMotivoRenunciaPayLoad,
      "FechaCese": formValues["FechaCese"],
      "RecomiendaReingreso": formValues["RecomiendaReingreso"] === "true" || formValues["RecomiendaReingreso"] === true,
      "MotivoRecomendacion": formValues["MotivoRecomendacion"],
      "FileViewModel": s3UploadFileResponseSustento || null,
    };
    const keys = ["IdMotivoRenuncia", "IdTipoCese","FechaCese", "RecomiendaReingreso", "MotivoRecomendacion", "FileViewModel"];
    const api = guardarEditarCeseAPi();

    setDatosConfirmar({keys, api, payload, sectionName, successSubmitCallback})

    if (fechaCese < yesterday) {
      showConfirmarEditarCeseModal(idCese);
    }
    

    if(fechaCese >= yesterday)
      await submitForm(keys, api, payload, sectionName, successSubmitCallback);
  };

  const callbackSuccessEditInfo = (response) => {
    const initialValues = {
      "IdMotivoRenuncia": response.data.IdMotivoRenuncia,
      "IdTipoCese": response.data.IdTipoCese,
      "FechaCese": response.data.FechaCeseValue,
      "RecomiendaReingreso": response.data.RecomiendaReingreso,
      "MotivoRecomendacion": response.data.MotivoRecomendacion,
      "Observaciones": response.data.Observaciones
    }

    setFormValues(initialValues);
    setJsonTextAreaOtros(response.data.DescripcionMotivoRenuncia);
    setDownloadDataMail(response.data.File);
    setRawData(response.data);
  }

  const init = async () => {
    const responseData = await manageGetRequest(getEditInfoCesesAPi(idCese), callbackSuccessEditInfo, false);
    let responseTipos = await executeGetRequest(getTiposCeseAPI());

    if (responseCode200(responseTipos)) {
        let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(responseTipos.data, "Value", "Text");
        setTiposDeCeseFiltradosOptions(options);
        const tipoCeseInicial = options.filter(item => item.value === responseData.data.IdTipoCese)[0]?? null;
        setJsonSelectedTiposCeseValue(tipoCeseInicial);
    } else {
        mostrarMensajeDeErrorConToast(responseTipos);
    }

    let response = await executeGetRequest(getMotivosRenunciaAPI());
    if (responseCode200(response)) {
      let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Text");
      setMotivosDeRenunciaFiltradosOptions(options);
      const motivoRenunciaInicial = options.filter(item => item.value === responseData.data.IdMotivoRenuncia)[0]??null;
      if(motivoRenunciaInicial?.label=="Otros") setShowTextArea(true);
      setJsonSelectedMotivoRenunciaValue(motivoRenunciaInicial)

    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const descargar = async function () {
    await descargarArchivo(downloadDataMail.OriginalFileName, downloadDataMail.Path)
  }

  return (
    <>
      <GenericModal showModal={showModal} width={"900px"} titulo={"Informar Cese"}>
        <div>
          <div>
            <form className="blue-standard-color">
              <div className="form-group row form-input-modal-contactanos">
                <label className="col-sm-4 col-form-label modal-label">Nombre</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control" value={rawData.NombresCompletos} disabled />
                </div>
              </div>
              <div className="form-group row form-input-modal-contactanos">
                <label className="col-sm-4 col-form-label modal-label">Sede</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control" value={rawData.Sede} disabled />
                </div>
              </div>
              <div className="form-group row form-input-modal-contactanos">
                <label className="col-sm-4 col-form-label modal-label">Celular</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control" value={rawData.Celular} disabled />
                </div>
              </div>
              <hr />
              <div className="form-group row form-input-modal-contactanos">
                <label className="col-sm-4 col-form-label modal-label">Tipo de cese<span className="required">*</span></label>
                <div className="col-sm-6">
                  <div className="black-color">
                    <SelectInput options={tiposDeCeseFiltradosOptions} jsonSelectedValue={jsonSelectedTiposCeseValue} setJsonSelectedValue={setJsonSelectedTiposCeseValue} isDisabled={false}
                      placeholder={"Seleccionar tipo de cese"} />
                  </div>
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdTipoCese-validation-message`}></p>
                </div>
              </div>
              {TIPO_CESE_IDS_MOTIVO.includes(jsonSelectedTiposCeseValue.IdEntity ) ? (
                <div className="form-group row form-input-modal-contactanos">
                  <label className="col-sm-4 col-form-label modal-label">Motivo de Renuncia<span className="required">*</span></label>
                  <div className={showTextArea? "col-sm-3" : "col-sm-6"}>
                    <div className="black-color">
                      <SelectInput options={motivosDeRenunciaFiltradosOptions} jsonSelectedValue={jsonSelectedMotivoRenunciaValue} setJsonSelectedValue={setJsonSelectedMotivoRenunciaValue} isDisabled={false}
                        placeholder={"Seleccionar motivo de renuncia"} />
                    </div>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdMotivoRenuncia-validation-message`}></p>
                  </div>
                  {showTextArea? (
                    <div className="col-sm-3">
                      <div className="black-color">
                        <textarea className="form-control" rows="3" name="DescripcionMotivoRenuncia"
                          onChange={(e)=>setJsonTextAreaOtros(e.target.value)} value={jsonTextAreaOtros}
                          placeholder="Escriba otro motivo de renuncia voluntaria" maxLength="100"
                        ></textarea>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="form-group row form-input-modal-contactanos">
                <label className="col-sm-4 col-form-label modal-label">Último día de labores<span className="required">*</span></label>
                <div className="col-sm-6">
                  <input
                    type="date"
                    className="form-control"
                    name="FechaCese"
                    value={formValues.FechaCese}
                    onChange={handleChange}>
                  </input>
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaCese-validation-message`}></p>
                </div>
              </div>
              <div className="form-group row form-input-modal-contactanos">
                <label className="col-sm-4 col-form-label modal-label">¿Recomendaría al colaborador para un reingreso futuro? <span className="required">*</span></label>
                <div className="col-sm-6 justify-content-center align-items-center">
                  <div className="form-check">
                    <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="RecomiendaReingreso" value={"true"} checked={formValues.RecomiendaReingreso || formValues.RecomiendaReingreso === "true"} onChange={handleChange}></input>
                    <label className="form-check-label modal-ceses-label-radio-button">
                      Sí
                    </label>
                  </div>

                  <div className="form-check">
                    <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="RecomiendaReingreso" value={"false"} checked={!formValues.RecomiendaReingreso || formValues.RecomiendaReingreso === "false"} onChange={handleChange}></input>
                    <label className="form-check-label modal-ceses-label-radio-button">
                      No
                    </label>
                  </div>
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-RecomiendaReingreso-validation-message`}></p>
                </div>
              </div>
              <InputTextArea isRequired label="¿Por qué?" name="MotivoRecomendacion" onChange={handleChange} value={formValues.MotivoRecomendacion} sectionName={sectionName} rows="3" maxLength="1000" placeholder="Máximo 1000 caracteres." colInput={6}/>
              <InputTextArea label="Observaciones" name="Observaciones"onChange={handleChange} value={formValues.Observaciones} sectionName={sectionName} rows="3" maxLength="1000" placeholder={"Máximo 1000 caracteres."} colInput={6} isDisabled />
              {downloadDataMail? (
                <div className="mb-2">
                  <div className="col-sm-12 black-color">
                    <button
                      onClick={descargar}
                      type='button'
                      className='btn btn-primary mb-0 custom-button-adjuntar mr-2'>
                      Descargar carta
                    </button>
                    <span>{downloadDataMail?.OriginalFileName?.length > 15? `${downloadDataMail.OriginalFileName.slice(0,15)}...${re.exec(downloadDataMail.OriginalFileName)[1]}`: downloadDataMail.OriginalFileName}</span>
                  </div>
                </div>
              ): null}
              
              <div>
                <div className="col-sm-12 black-color">
                  <GenericUploadFileV2 uploadFileToS3={setS3UploadFileResponseSustento} id={UPDATE_FILE_INPUT_ID} textButton={"Adjuntar nueva carta"} extensionesValidas={["pdf"]} ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                    subiendoArchivoPublicState={subiendoArchivoSustento} setSubiendoArchivoPublicState={setSubiendoArchivoSustento} />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FileViewModel-validation-message`}></p>
                </div>
              </div>
              <div>
                <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light"> <strong>* El formato permitido es un .pdf de máximo 8MB.</strong></label>
              </div>
            </form>
          </div>
        </div>
        <div>
          <div className="modal-footer mt-3">
            <div className="message font-gothan-light" style={{ 'width': '500px' }}>
              <strong>
                <p>* Campos obligatorios</p>
              </strong>
              <strong>
                <p>* Los documentos adjuntos son evidencia para la validación de la carta de renuncia.</p>
              </strong>
            </div>
            <div className="button">
              <button
                className="btn btn-primary generic-button-ficha-modal font-gothan-light"
                onClick={submitModalRegistrarCeseProxy}
                disabled={subiendoArchivoSustento}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </GenericModal>
    </>
  )
};

export default EditarCeseModal;
import React from 'react'
import { Div } from './Components';
import InputDate from '../../../../components/modalComponents/InputDate';
import InputSelect from '../../../../components/modalComponents/InputSelect';
import InputText from '../../../../components/modalComponents/InputText';

import GenericTooltip from '../../../../components/GenericTooltip/GenericTooltip';
import { addDays, getTodayDate, toIsoForInput, parseStringToDate, getMonthsBetweenDates } from '../../../../helper/utils';
import { keys } from '@material-ui/core/styles/createBreakpoints';

const Gestante = ({ commonProps, selectProps, keys: KEYS, isDisabled = false, isCreate = false }) => {

  const NUEVE_MESES_FUTURO = 273;

  const calcularNroMeses = () => {

    let numMonths = 0;

    if (isDisabled === true){
      numMonths = commonProps(KEYS.meses)?.value
    }
    else{
      if (commonProps(KEYS.fechaUltMenstruacion)?.value){
        let fumText = commonProps(KEYS.fechaUltMenstruacion)?.value;
        let fumDate = parseStringToDate(fumText);
        numMonths = getMonthsBetweenDates(fumDate, new Date());
      }else{
        return "-";
      }
    }

    if (numMonths > 0)
      if (numMonths === 1)
        return "1 mes"
      else 
        return numMonths + " meses"

    return "0 meses";
  }
  
  const fechasDeCondicion = (
    <>
      <Div>
        <InputDate
          label={"Fecha de inicio"}
          name={KEYS.fechaInicioCondicion}
          {...commonProps(KEYS.fechaInicioCondicion)}
          isDisabled={true}
        />
      </Div>
      <Div>
        <InputDate
          label={"Fecha de fin"}
          name={KEYS.fechaFinCondicion}
          {...commonProps(KEYS.fechaFinCondicion)}
          isDisabled={true}
        />
      </Div>
    </>
  );

  return (
    <>
      {!isCreate && fechasDeCondicion}
      <Div>
        <InputDate
          label={TooltipText("FPP", "Fecha Probable de Parto")}
          {...commonProps(KEYS.fechaProbable)}
          min={toIsoForInput(addDays(getTodayDate(), 1))}
          max={toIsoForInput(addDays(getTodayDate(), NUEVE_MESES_FUTURO))}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        />
      </Div>
      <Div>
        <InputDate
          label={TooltipText("FUM", "Fecha Última Menstruación")}
          {...commonProps(KEYS.fechaUltMenstruacion)}
          max={toIsoForInput(getTodayDate())}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        />
      </Div>
      <Div>
        {/* <InputSelect
          label="Tiempo Gestación"
          {...selectProps(KEYS.meses)}
          isDisabled={isDisabled}
          isRequired={!isDisabled}
        /> */}
        {/* row.NombresCompletos */}
         <InputText label='Tiempo Gestación' value={calcularNroMeses()} isDisabled />
      </Div>
    </>
  );
}

export default Gestante;

const TooltipText = (id, text) => (
  <>
    <label>{id}</label>
    <GenericTooltip id={id} text={text}/>
  </>
)
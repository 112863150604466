import React, { useState, useEffect, useContext } from 'react';
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputSelect from '../../../components/modalComponents/InputSelect';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import { AuthContext } from '../../../hooks/context/authContext';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { createSolicitudDescuentoPensionAPI, getSolicitudDescuentoPensionToCreateAPI } from '../../../consumers/backendApisUrls';

const SolicitudDescuentoPension = ({ toggleModal }) => {

  const {state} = useContext(AuthContext);
  const IMAGE_LOCK = "https://intranet.innovaschools.edu.pe/Content/image/lock_1.png";
  
  const KEYS = {hijosMatriculados: "HijoMatriculados", politicas: "Politicas", hijosSeleccionados: "HijosSeleccionados", LineamientosFile: "LineamientosFile"};
  const SECTION_NAME = "solicitudDescuentoPension";
  const idEmpleado = state.user.id_empleado;

  const [formValues, setFormValues] = useState({});
  
  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executePost] = useManagePostRequest();

  const [filas, setFilas] = useState([]);
  const [tiposDescuento, setTiposDescuento] = useState([]);

  const[mostrarBotonGuardar, setMostrarBotonGuardar] = useState(false);

  const init = async () => {
    const api = getSolicitudDescuentoPensionToCreateAPI(idEmpleado);
    const response = await executeGet(api);
    if (responseCode200(response)){
      successInitCallback(response);
    } else{
      const modalMensaje = response.response.data.Message;
      setModalEnabled({ isEnable: true, component: ModalMensaje, data: { toggleModal, modalMensaje, modalImage: IMAGE_LOCK }});
    }
    setIsFetching(false);
  }
  
  const successInitCallback = ({ data }) => {
    setFormValues(data);
    setFilas(data[KEYS.hijosMatriculados]);
    setTiposDescuento(data?.DescuentosDisponibles);
    setMostrarBotonGuardar(data[KEYS.hijosMatriculados].filter(o => o.TieneSolicitudAprobadaOpendienteCreadaAnteriormente === false).length >= 1)
  }

  const handleChangeSelect = (e, idHijoMatriculado) => {
    var selectedItem = tiposDescuento.find(o=>o.value === e.target.value.value)

    var filasTemp = [...filas];
    var foundIndex = filasTemp.findIndex(x => x.IdHijoMatriculado === idHijoMatriculado)
    filasTemp[foundIndex] = {...filasTemp[foundIndex], optionDescuentoSeleccionado: selectedItem, Descuento: selectedItem.value}
    setFilas(filasTemp);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = createSolicitudDescuentoPensionAPI();
    const payload = getPayload();
    try {
      await executePost(api, payload, () => toggleModal());
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    return {
      IdEmpleado: idEmpleado,
      Hijos: filas.filter(o => o.TieneSolicitudAprobadaOpendienteCreadaAnteriormente === false)
    }
  }
  
  useEffect(() => {
    init();
  }, [])

  const GetOptions =  () => {
      let itemDisponibleSoloParaUnHijo = tiposDescuento.filter(o => o.DisponibleSoloParaUnHijo === true)

      if (itemDisponibleSoloParaUnHijo.length > 0){
        let itemSoloParaUnHijo = itemDisponibleSoloParaUnHijo[0]
        var itemSoloParaUnHijoYaEstaSeleccionado = filas?.filter(o => o.Descuento === itemSoloParaUnHijo?.value).length > 0 ;

        if (itemSoloParaUnHijoYaEstaSeleccionado === true){
          return tiposDescuento.filter(o => o?.value !== itemSoloParaUnHijo?.value);
        }
      }
      return tiposDescuento
  }

  if (isFetching) return <SpinnerLoading />

  return (
    <>
    <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled}/>
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} width='950px' titulo='Solicitud de descuento en la pensión escolar' isSubmitting={isSubmitting} hasSaveButton={mostrarBotonGuardar}>
     
    <div className='table-scroll' style={{minHeight: "50px"}}>
        <table className='table table-hover'>
          <thead className='listar-thead'>
            <tr>
              <th className='text-center'>Nombre</th>
              <th className='text-center'>DNI</th>
              <th className='text-center'>Colegio Matriculado</th>
              <th className='text-center'>Descuento</th>
            </tr>
          </thead>
          <tbody>
            {filas?.map((hijo, index) => 
              (<tr key={`${index}-${hijo.NumeroDocumento}`}>
                <td style={{paddingBottom: '0'}}>{hijo.NombresCompletos}</td>
                <td style={{paddingBottom: '0'}} className='text-center'>{hijo.NumeroDocumento}</td>
                <td style={{paddingBottom: '0'}}>{hijo.Colegio}</td>
                <td style={{paddingRight: '0', paddingBottom: '0'}}>
                  <div style={{width: '190px'}}>
                    {hijo?.TieneSolicitudAprobadaOpendienteCreadaAnteriormente ===true ?
                        <p>{hijo?.DescuentoDeSolicitud} ({hijo?.EstadoSolicitud})</p>
                    :
                      <InputSelect direction={''} name={''} options={GetOptions()} value={hijo?.optionDescuentoSeleccionado} onChange={(e)=>handleChangeSelect(e,hijo?.IdHijoMatriculado)} sectionName={SECTION_NAME} />
                    }
                  </div>
                </td>
              </tr>))
            }
          </tbody>
        </table>
      </div>
     
      <div className="form-row pt-3" style={{borderTop: "1px solid #dee2e6"}}>
        <ul className='d-flex flex-column'>
          <li>{formValues?.MensajeFooterSolicitar}</li>
          <li className=''>Mira el instructivo <a className="ficha-residencia-ver-info-icon-mensaje" target="_blank" rel="noopener noreferrer" href={formValues[KEYS.LineamientosFile] ?? ""}>Aquí</a></li>
          <li className=''>Mira aquí la política <a className="ficha-residencia-ver-info-icon-mensaje" target="_blank" rel="noopener noreferrer" href={formValues[KEYS.politicas]?.at(0)?.Path ?? ""}>Aquí</a></li>
        </ul>
      </div>
    </ModalForm>
    </>
  )
}

export default SolicitudDescuentoPension

const ModalMensaje = ({toggleModal, manageSearch, modalMensaje, modalImage, styleFicha = {}, hasBackButton = true}) => {

  const closeAll = () => {
    toggleModal();
    if (manageSearch) manageSearch();
  }

  return (
    <div className="modal-response-ficha-background">
      <div className="modal-response-ficha-content" style={{ width: "700px" }}>
        {modalImage &&
            <img src={modalImage} />
        }
        <p className="modal-response-ficha-content-message px-5 text-center" style={styleFicha}>
          {modalMensaje}
        </p>
        {hasBackButton &&
          <button className="modal-response-ficha-content-button" onClick={closeAll}> Volver </button>
        }
      </div>
    </div>
  )
}
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import logo from '../../image/logo_menu.svg'
import { mensajeCargaMasivaEnPagina } from '../../constants/messages'

const PageLoadingRedirectHomeModal = () => {
  const history = useHistory();

  const handleReturn = (e) => {
    e.preventDefault();
    history.push("/");
  }

  return (
    <Modal>
      <ImgDiv>
        <Spinner />
        <img src={logo} alt="#"/>
      </ImgDiv>
      <Message>
        <h1>{mensajeCargaMasivaEnPagina}</h1>
      </Message>
      <Button type='button' onClick={handleReturn}>Entendido</Button>
    </Modal>
  )
}

export default PageLoadingRedirectHomeModal

const pulse = keyframes`
  50% {
    opacity: 0.8;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Modal = styled.div`
  width: 40%;
  height: 50%;
  min-height: 400px;
  min-width: 700px;
  text-align: center;
  border-radius: 2rem;
  background: #f7f8fa;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  position: relative;
  justify-content: space-between;
  margin:auto
`;

const ImgDiv = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    height: 100%;
    animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
`;

const Spinner = styled.div`
  position: absolute;
  border: 4px solid transparent;
  border-top: 4px solid #3a87c8;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  animation: ${spin} 1s linear infinite;
  margin: 0 auto;
`;

const Message = styled.div`
  height: 30%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 16px;
    text-align: justify;
    font-weight: bold;
  }
`;

const Button = styled.button`
  padding: 15px 20px;
  border-radius: 15px;
  border: 1px solid #3a87c8;
  color: white;
  font-weight: bold;
  background-color: #3a87c8;
  transition: all .3s ease-in-out;

  &:hover{
    transform: scale(1.1);
  }
`;


import React from 'react';
import { ACTIVIDADES_PENDIENTES_MODALES } from "../../constants/hardCodedConstants";
import { agregarStringAArraydDeStringsSiNoExiste, eliminarStringDeArraydDeStrings, stringSeEncuentraEnArray } from "../../helper/utils";
import { reprocesarActividadAPI , RevertirActividadesPendientes } from "../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../consumers/httpRequiestsUtils";
import { toast } from "react-toastify";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ACTIONS } from './reducer/ActividadesPendientesReducer';
import DocumentoObservadoModal from './modals/DocumentoObservadoModal';
import DocumentosPendientesModal from './modals/DocumentosPendientesModal';
import ActualizarCorreoCorporativoModal from './modals/ActualizarCorreoCorporativoModal';
import IndicarAmonestacionesModal from './modals/IndicarAmonestacionesModal';
import KitCharlaMamaMaternidad from './modals/KitCharlaMamaMaternidad';
import FirmaCartaTRegistro from './modals/FirmaCartaTRegistro';
import { estados } from '../../constants/states';

const TableWebBody = ({ actividadesFiltradas, showDetalleRegistro, idsEmpleadoFlujoActividadConCheck, setIdsEmpleadoFlujoActividadConCheck, showAprobar,
    showDetallePasosDeActividadesModal, showVerificarERPendienteModal,
    showGenerarLiquidacionModal, showConfirmarPrestamoModal,
    showCoordinarDevolucionModal, showConfirmarEstadoRecepcionEquiposModal,
    showActualizarLiquidacionModal, manageSearch, toggleObservarActividadModal, toggleReasignarActividadModal,
    showFirmarCartaAutorizacionModal, showValidarCartaAutorizacionModal,
    showFirmarCartaCompromisoVacunacion, showFirmarCompromisoLecturaPoliticaModal, showValidarCartaCompromisoVacunacion, showValidarPlantillaCartaCompromisoVacunacion,
    showFirmarCartaIncrementoModal,
    showCartaDeVacunacionModal,
    showFirmaCartaAprobadosLineaCarreraModal,
    showFirmaCartaBonoModal,
    showFirmaDocumentoTeletrabajoModal,
    showFirmaCompraVacacionesModal,
    showFirmaCartaCambioPuestoModal,
    showFirmaCartaIncremento2023Modal,
    showFirmaConvenioBonoTrasladoModal,
    showFirmaCartaIncrementoBO2023Modal,
    dispatch,
    setModalEnable,
    showTestInduccionModal,
    showEncuestaInduccionModal,
    showCorreoCorporativoModal,
    showInduccionVirtualModal,
    showConfirmarAsistenciaModal,
    showConfirmarAccesoDMModal,
    showConfirmarAccesoJiraModal,
    showConfirmarIngresoColaboradoNuevoModal,
    showFirmaConvenioCambioFuncionesModal,
    showFirmaCartaObjetivoModal,
    showFirmaCartaObjetivo2023y2024Modal,
    showFirmaLineaCarrera2023Modal,
    showFirmaCartaIncrementoSede2024Modal,
    showFirmaCartaIncrementoModal,

    showFirmaConvenioMutuoDisensoModal,
    showFirmaPoliticaBonoTotModal,
    showFirmaDocumentoEMOModal,
    showFirmaConvenioCambioPuestoModal,
    showFirmaRecompensaTotalSede2024Modal,
    showFirmaRecompensaTotalBO2024Modal,
    showFirmaDocumentoProrrogaModal,
    showFirmaDocumentoResultadoEvaluacion2024,
    showSeleccionarHorarioLactanciaModal,
    showValidarHorarioLactanciaModal,
    showAprobarHorarioLactanciaModal,
    showFirmarRecepcionHorarioLactanciaModal,
    showFirmarDocumentoCanjeDMEssaludCITT,
    showEntregarDocumentoTramiteEssalud,
    showValidarDocumentoTramiteEssalud,
    showSubirDocumentoDMCITT,
    showValidarDocumentoDMCITT
}) => {

    const allEncuestaTeletrabajo = [ACTIVIDADES_PENDIENTES_MODALES.Modal_EncuestaTeletrabajo_Link_Capacitacion.id, ACTIVIDADES_PENDIENTES_MODALES.Modal_EncuestaTeletrabajo_Instructivo.id, ACTIVIDADES_PENDIENTES_MODALES.Modal_EncuestaTeletrabajo_Encuesta.id]
    const CODES_COMPONENTS = {
        [ACTIVIDADES_PENDIENTES_MODALES.Modal_ni_documentoObservado.id]: DocumentoObservadoModal,
        [ACTIVIDADES_PENDIENTES_MODALES.Modal_ni_documentosPendientes.id]: DocumentosPendientesModal,
        [ACTIVIDADES_PENDIENTES_MODALES.Modal_ni_actualizar_correo.id]: ActualizarCorreoCorporativoModal,
        [ACTIVIDADES_PENDIENTES_MODALES.Modal_indicar_amonetaciones.id]: IndicarAmonestacionesModal,
        [ACTIVIDADES_PENDIENTES_MODALES.Modal_Maternidad_confirmarAsistenciaCharla.id]: KitCharlaMamaMaternidad,
        [ACTIVIDADES_PENDIENTES_MODALES.Modal_TRegistro_subirDocumentoFirmado.id]: FirmaCartaTRegistro,
    }
    const [executePostRequest] = usePostRequest();

    const masDetalle = (e) => {
        if (e.target.classList.contains('more')) {
            e.target.classList.remove('more');
            e.target.classList.add('single-line-text');
        } else {
            e.target.classList.add('more');
            e.target.classList.remove('single-line-text');
        }
    };

    const checkboxClic = (e, actividad) => {
        let checked = e.target.checked;
        console.debug("IdEmpleadoFlujoActividad ", actividad.IdEmpleadoFlujoActividad);
        let idEmpleadoFlujoActividad = actividad["IdEmpleadoFlujoActividad"];

        let tempSolicitudes = [...idsEmpleadoFlujoActividadConCheck];

        if (checked === true && !stringSeEncuentraEnArray(idEmpleadoFlujoActividad, tempSolicitudes)) {
            tempSolicitudes = agregarStringAArraydDeStringsSiNoExiste(idEmpleadoFlujoActividad, tempSolicitudes);
            console.debug("new IdsFichaCamposSolicitudesConCheck: ", tempSolicitudes);
            setIdsEmpleadoFlujoActividadConCheck(tempSolicitudes);
        } else if (checked === false && stringSeEncuentraEnArray(idEmpleadoFlujoActividad, tempSolicitudes)) {
            tempSolicitudes = eliminarStringDeArraydDeStrings(idEmpleadoFlujoActividad, tempSolicitudes);
            console.debug("new IdsFichaCamposSolicitudesConCheck: ", tempSolicitudes);
            setIdsEmpleadoFlujoActividadConCheck(tempSolicitudes);
        }
    };

    const reprocesarActividad = async (IdEmpleadoFlujoActividad) => {
        let payload = {
            "IdEmpleadoFlujoActividad": IdEmpleadoFlujoActividad
        };

        console.debug("payload ", payload);
        let response = await executePostRequest(reprocesarActividadAPI(), payload);
        if (responseCode200(response)) {
            toast.success((response?.data?.Message) ? response?.data?.Message : "Se Reprocesó la actividad exitosamente");
            await manageSearch();
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const cambiarEstadoPendienteActividades = async (IdEmpleado,IdEmpleadoFlujoActividad ) =>{

        let response = await executePostRequest(RevertirActividadesPendientes(IdEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            toast.success((response?.data?.Message) ? response?.data?.Message : "Se recvertio la actividad");
            await manageSearch();
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }





    return (
        <tbody className="listar-tbody">
            {actividadesFiltradas.map((actividad, i) => (
                <tr key={i}>
                    {showAprobar &&
                        <td><input type="checkbox" onClick={(e) => checkboxClic(e, actividad)}></input></td>
                    }
                    <td>{actividad.FechaRegistro}</td>
                    <td>{actividad.NombreEmpleado}</td>
                    <td>{actividad.EstadoEmpleado}</td>
                    <td>{actividad?.FechaIngreso}</td>
                    <td>{actividad?.Sede}</td>
                    <td>{actividad.NombreFlujo}</td>
                    <td>{actividad.Cargo}</td>
                    <td>
                        <a className="text-link-appereance" onClick={() => showDetallePasosDeActividadesModal(actividad.IdEmpleadoFlujoActividad)}>{actividad.NombreActividad}</a>
                    </td>
                    <td>{actividad.Estado}</td>
                    <td style={{ minWidth: 250, maxWidth: 300 }} className="single-line-text" onClick={(e) => masDetalle(e)}>
                        {actividad.Mensaje}
                        <p></p>
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Validacion_Ficha.id &&
                            <u><a className="text-link-appereance" onClick={() => showDetalleRegistro(actividad.IdEmpleadoFlujoActividad)}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Verificar_ER_Pendiente.id &&
                            <u><a className="text-link-appereance" onClick={() => showVerificarERPendienteModal(actividad.IdEmpleadoFlujoActividad)}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Generar_Liquidacion.id &&
                            <u><a className="text-link-appereance" onClick={() => showGenerarLiquidacionModal(actividad.IdEmpleadoFlujoActividad)}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Confirmar_Prestamo.id &&
                            <u><a className="text-link-appereance" onClick={() => showConfirmarPrestamoModal(actividad.IdEmpleadoFlujoActividad)}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Coordinar_Devolucion.id &&
                            <u><a className="text-link-appereance" onClick={() => showCoordinarDevolucionModal(actividad.IdEmpleadoFlujoActividad)}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Confirmar_Estado_Recepcion_Equipos.id &&
                            <u><a className="text-link-appereance" onClick={() => showConfirmarEstadoRecepcionEquiposModal(actividad.IdEmpleadoFlujoActividad)}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Ampliar_Liquidacion.id &&
                            <u><a className="text-link-appereance" onClick={() => showActualizarLiquidacionModal(actividad.IdEmpleadoFlujoActividad)}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Reprocesar_Actividad.id &&
                            <u><a className="text-link-appereance" onClick={async () => { await reprocesarActividad(actividad.IdEmpleadoFlujoActividad) }}>Reprocesar Actividad</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Carta_Autorizacion.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmarCartaAutorizacionModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Firmar carta de autorización</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Validar_Firma_Carta_Autorizacion.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showValidarCartaAutorizacionModal(actividad.IdEmpleadoFlujo, actividad.IdEmpleadoFlujoActividad) }}>Verificar carta de autorización cargado</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Validar_Plantilla_Compromiso_Vacunacion.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showValidarPlantillaCartaCompromisoVacunacion(actividad.IdEmpleadoFlujoActividad) }}>Validar plantilla</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Carta_Compromiso_Vacunacion.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmarCartaCompromisoVacunacion(actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firma_Compromiso_Lectura_Politicas.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmarCompromisoLecturaPoliticaModal(actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Validar_Carta_Compromiso_Vacunacion_Firmado.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showValidarCartaCompromisoVacunacion(actividad.IdEmpleadoFlujoActividad) }}>Validar documento firmado</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Carta_Incremento.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmarCartaIncrementoModal(actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Carta_Compromiso_Vacunacion.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showCartaDeVacunacionModal(actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Carta_Aprobado_LineaCarrea.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaAprobadosLineaCarreraModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_carta_bono.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaBonoModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Documento_Teletrabajo.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaDocumentoTeletrabajoModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_CompraVacaciones.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCompraVacacionesModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_CartaCambioPuesto.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaCambioPuestoModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Carta_Incremento_2023.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaIncremento2023Modal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_BonoTraslado.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaConvenioBonoTrasladoModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad?.Code && (allEncuestaTeletrabajo.includes(actividad?.Code)) &&
                            <u><a className="text-link-appereance" onClick={() => { dispatch({ type: ACTIONS.IS_MODAL_LOADED, payload: actividad?.Code, idEmpleadoFlujoActividad: actividad?.IdEmpleadoFlujoActividad }) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_CartaIncrementoBO2023.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaIncrementoBO2023Modal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Test_Induccion.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showTestInduccionModal(actividad.IdEmpleadoFlujoActividad, actividad.NombreEmpleado) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Encuesta_Induccion.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showEncuestaInduccionModal(actividad.IdEmpleadoFlujoActividad, actividad.NombreEmpleado) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_CreaCorreo.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showCorreoCorporativoModal(actividad.IdEmpleadoFlujoActividad, actividad) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Archivos.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showInduccionVirtualModal(actividad.IdEmpleadoFlujoActividad) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_ConfirmarAsistencia.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showConfirmarAsistenciaModal(actividad.IdEmpleadoFlujoActividad) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Confirmacion_jira.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showConfirmarAccesoJiraModal(actividad.IdEmpleadoFlujoActividad) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Confirmacion_dm.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showConfirmarAccesoDMModal(actividad.IdEmpleadoFlujoActividad) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_ConfirmacionFechaIngreso.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showConfirmarIngresoColaboradoNuevoModal(actividad.IdEmpleadoFlujoActividad, actividad) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_ConvenioCambioFunciones.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaConvenioCambioFuncionesModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_carta_bono_v2.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaObjetivo2023y2024Modal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_carta_bono_v3.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaObjetivoModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad, actividad.NombreActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code && CODES_COMPONENTS[actividad.Code] &&
                            <u><a className="text-link-appereance" onClick={async () => { setModalEnable({ isEnable: true, component: CODES_COMPONENTS[actividad.Code], data: { idEmpleadoFlujoActividad: actividad.IdEmpleadoFlujoActividad, idEmpleadoFlujo: actividad.IdEmpleadoFlujo } }) }}>Ver detalle</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_LineaCarrera2023.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaLineaCarrera2023Modal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Carta_Incremento_2024.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaIncrementoSede2024Modal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_NI_Carta_Incremento.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaCartaIncrementoModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Convenio_Mutuo_Disenso.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaConvenioMutuoDisensoModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Carta_Bono_Tot.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaPoliticaBonoTotModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firma_Documento_EMO.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaDocumentoEMOModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_ConvenioPuesto.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaConvenioCambioPuestoModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_carta_remcompesa_Sedev1.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaRecompensaTotalSede2024Modal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_carta_remcompesa_BOv1.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaRecompensaTotalBO2024Modal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Documento_Prorroga.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaDocumentoProrrogaModal(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }
                        
                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Firmar_Resultado_evaluacion2024.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmaDocumentoResultadoEvaluacion2024(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad) }}>Cargar documento</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Condicion_GuardarHorarioLactancia.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showSeleccionarHorarioLactanciaModal(actividad.IdEmpleadoFlujoActividad)}}>Ver detalle</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Condicion_ValidarHorarioLactancia.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showValidarHorarioLactanciaModal(actividad.IdEmpleadoFlujoActividad)}}>Ver detalle</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Condicion_AprobarHorarioLactancia.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showAprobarHorarioLactanciaModal(actividad.IdEmpleadoFlujoActividad)}}>Ver detalle</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Condicion_EnviarConvenioHorarioLactancia.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmarRecepcionHorarioLactanciaModal(actividad.IdEmpleadoFlujoActividad)}}>Cargar documento</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.Modal_Condicion_GuardarDocumentoCanjeDMEssaludCitt.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showFirmarDocumentoCanjeDMEssaludCITT(actividad.IdEmpleadoFlujoActividad);}}>Cargar documento</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.canjeDMcitt_entregarDocumentoTramiteEssalud.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showEntregarDocumentoTramiteEssalud(actividad.IdEmpleadoFlujo, actividad.IdEmpleadoFlujoActividad);}}>{actividad.LinkNameToOpenModal}</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.canjeDMcitt_validarDocumentoTramiteEssalud.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showValidarDocumentoTramiteEssalud(actividad.IdEmpleadoFlujo, actividad.IdEmpleadoFlujoActividad);}}>{actividad.LinkNameToOpenModal}</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.canjeDMcitt_subirDocumentoDMcitt.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showSubirDocumentoDMCITT(actividad.IdEmpleadoFlujo, actividad.IdEmpleadoFlujoActividad);}}>{actividad.LinkNameToOpenModal}</a></u>
                        }

                        {actividad.Code !== null && actividad.Code === ACTIVIDADES_PENDIENTES_MODALES.canjeDMcitt_validarDocumentoDMcitt.id &&
                            <u><a className="text-link-appereance" onClick={async () => { showValidarDocumentoDMCITT(actividad.IdEmpleadoFlujo, actividad.IdEmpleadoFlujoActividad);}}>{actividad.LinkNameToOpenModal}</a></u>
                        }
                    </td>
                    <td>
                        <div className="d-flex justify-content-center">
                            {actividad?.PuedeReasignar === true &&
                                <div>
                                    <FontAwesomeIcon icon='people-arrows' className="hand-pointer generic-action-icon" title={`Reasignar actividad`} onClick={() => toggleReasignarActividadModal(actividad.IdEmpleadoFlujoActividad)} />
                                </div>
                            }

                            {actividad?.PuedeObservar === true &&
                                <div    >
                                    <FontAwesomeIcon icon='times' className="hand-pointer generic-action-icon" title={`Observar adjuntos de actividad`} onClick={() => toggleObservarActividadModal(actividad.IdEmpleadoFlujoActividad)} />
                                </div>
                            }

                             {actividad?.PuedeRevertir === true &&

                           <div style={{ marginLeft: '8px' }}>
                            <FontAwesomeIcon icon='times' className="hand-pointer generic-action-icon m" title={`reprocesar`} onClick={() => cambiarEstadoPendienteActividades(actividad.IdEmpleado, actividad.IdEmpleadoFlujoActividad)} />
                            </div>

                            }
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

export default TableWebBody;

import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {useForm} from "../../../hooks/useForm/UseForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    getTodayDate,
    getCurrentDay,
    getCurrentMonth,
    getCurrentYear,
    addDays,
    isEmpty,
    formatNumber,
    mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric
} from "../../../helper/utils";
import {toast} from "react-toastify";
import {GENERIC_SUCCESS_TOAST_MESSAGE,TIPO_CESE_IDS_MOTIVO,TIPO_CESE_RENUNCIA} from "../../../constants/hardCodedConstants";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import SelectInput from "../../../components/Select/SelectInput";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {buscarColaboradorCeseAPI, buscarMisColaboradoresPorCriterioAPI, crearCeseAPI, getMotivosRenunciaAPI,getTiposCeseAPI} from "../../../consumers/backendApisUrls";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import {AlertErrorMaxFileSizeCeses} from "../alerts/AlertErrorMaxFileSizeCeses";
import InputTextArea from '../../../components/modalComponents/InputTextArea';

const RegistrarCeseModal = ({showModal, manageSearch}) => {
    const [subiendoArchivoSustento, setSubiendoArchivoSustento] = useState(false);
    const UPDATE_FILE_INPUT_ID = "addCeseModal-sustento";
    const sectionName = "RegistrarCeseModal";
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [s3UploadFileResponseSustento, setS3UploadFileResponseSustento] = useState({});

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [colaboradoresFiltradosOptions, setColaboradoresFiltradosOptions] = useState([]);
    const [jsonSelectedColaboradorValue, setJsonSelectedColaboradorValue] = useState({});
    const [cargandoColaboradores, setCargandoColaboradores] = useState(false);
    const [selectInputColaboresPlaceholder, setSelectInputColaboresPlaceholder] = useState("");
    const [currentYear] = useState(getCurrentYear());
    const [currentMonth] = useState(getCurrentMonth());
    const [currentDay] = useState(getCurrentDay());
    const next30Days = addDays(getTodayDate(), 30);
    const [endYear] = useState(getCurrentYear(next30Days));
    const [nextMonth] = useState(getCurrentMonth(next30Days));
    const [nextDay] = useState(getCurrentDay(next30Days));

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    const [motivosDeRenunciaFiltradosOptions, setMotivosDeRenunciaFiltradosOptions] = useState([]);
    const [jsonSelectedMotivoRenunciaValue, setJsonSelectedMotivoRenunciaValue] = useState({});

    const [tiposDeCeseFiltradosOptions, setTiposDeCeseFiltradosOptions] = useState([]);
    const [jsonSelectedTiposCeseValue, setJsonSelectedTiposCeseValue] = useState({});

    const [jsonTextAreaOtros, setJsonTextAreaOtros] = useState("");

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(()=>{
        setJsonTextAreaOtros("")
        setJsonSelectedMotivoRenunciaValue({})
    },[jsonSelectedTiposCeseValue])

    const submitModalRegistrarCeseProxy = async () => {
        console.debug("submitModalRegistrarCeseProxy ", formValues)

        let descripcionMotivoRenunciaPayLoad = jsonTextAreaOtros;
        if (jsonSelectedMotivoRenunciaValue?.label === "Otros") {
            if (jsonTextAreaOtros === '') descripcionMotivoRenunciaPayLoad = 'No especifica'
        }
        else descripcionMotivoRenunciaPayLoad = null;

        let payload = {
            "IdEmpleado": jsonSelectedColaboradorValue["value"],
            "IdTipoCese": jsonSelectedTiposCeseValue["value"],
            "IdMotivoRenuncia": TIPO_CESE_IDS_MOTIVO.includes(jsonSelectedTiposCeseValue.IdEntity)? jsonSelectedMotivoRenunciaValue?.value:null,
            "DescripcionMotivoRenuncia": descripcionMotivoRenunciaPayLoad,
            "FechaCese": formValues["FechaCese"],
            "RecomiendaReingreso": formValues["RecomiendaReingreso"] === "true" || formValues["RecomiendaReingreso"] === true,
            "MotivoRecomendacion": formValues["MotivoRecomendacion"],
            "Observaciones": formValues["Observaciones"],
            "FileViewModel": s3UploadFileResponseSustento,
        };

        console.debug("payload ", payload);

        let response = await executePostRequest(crearCeseAPI(), payload);

        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdMotivoRenuncia", "IdTipoCese","FechaCese", "RecomiendaReingreso", "MotivoRecomendacion", "FileViewModel"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }

    };

    const init = async () => {
        let formInputValues = getInitialFormValues();
        setFormValues(formInputValues);

        let responseTipos = await executeGetRequest(getTiposCeseAPI());
        if (responseCode200(responseTipos)) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(responseTipos.data, "Value", "Text");
            setTiposDeCeseFiltradosOptions(options);
        } else {
            mostrarMensajeDeErrorConToast(responseTipos);
        }

        let response = await executeGetRequest(getMotivosRenunciaAPI());
        if (responseCode200(response)) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Text");
            setMotivosDeRenunciaFiltradosOptions(options);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }

        
    };

    const getInitialFormValues = () => {
        return {
            "IdMotivoRenuncia": null,
            "FechaCese": "",
            "RecomiendaReingreso": "false"
        };
    };

    const buscarColaborador = async () => {
        console.debug("buscar colaborador: ", {criterioDeBusqueda});

        if (isEmpty(criterioDeBusqueda)) {
            toast.warn("Ingresar un criterio de búsqueda.");
            return;
        }

        setCargandoColaboradores(true);
        setSelectInputColaboresPlaceholder("");
        const payload = {
            "Criterio": criterioDeBusqueda
        }
        let response = await executePostRequest(buscarColaboradorCeseAPI(), payload);

        if (responseCode200(response)) {
            let options = response.data.map((option) => ({value: option["IdEmpleado"], label: option["FullName"]}));
            setColaboradoresFiltradosOptions(options);
            setSelectInputColaboresPlaceholder("Seleccionar colaborador");
        } else {
            setColaboradoresFiltradosOptions([]);
            setSelectInputColaboresPlaceholder("");
            mostrarMensajeDeErrorConToast(response);
        }
        setCargandoColaboradores(false);
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            buscarColaborador();
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"900px"} titulo={"Informar Cese"}>
                <div>
                    <div>
                        <form className="blue-standard-color">
                            <hr/>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Buscar colaborador</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control modal-addCese-input-buscador" placeholder='Nombre, Apellidos, DNI' name="datosColaborador" value={criterioDeBusqueda ?? ""}
                                           onChange={(e) => {
                                               setCriterioDeBusqueda(e.target.value);
                                           }}
                                           onKeyPress={handleKeypress}></input>
                                    <button
                                        onClick={buscarColaborador}
                                        type='button'
                                        className='btn btn-primary generic-button-ficha-modal boton-buscar-generic-listar font-gothan-light'>
                                        Buscar
                                    </button>
                                </div>
                            </div>

                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Seleccionar Colaborador<span className="required">*</span></label>
                                <div className="col-sm-6 modal-ceses-input-select-colaboradores-filtrados">
                                    <SelectInput options={colaboradoresFiltradosOptions} jsonSelectedValue={jsonSelectedColaboradorValue} setJsonSelectedValue={setJsonSelectedColaboradorValue} isDisabled={false} placeholder={selectInputColaboresPlaceholder}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEmpleado-validation-message`}></p>
                                </div>
                                {cargandoColaboradores && (<><FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner modal-ceses-input-select-spinner-cargando-colaboradores"/></>)}
                            </div>
                            <hr/>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Tipo de cese<span className="required">*</span></label>
                                <div className="col-sm-6">
                                    <div className="black-color">
                                        <SelectInput options={tiposDeCeseFiltradosOptions} jsonSelectedValue={jsonSelectedTiposCeseValue} setJsonSelectedValue={setJsonSelectedTiposCeseValue} isDisabled={false}
                                            placeholder={"Seleccionar tipo de cese"} />
                                    </div>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdTipoCese-validation-message`}></p>
                                </div>
                            </div>
                            { TIPO_CESE_IDS_MOTIVO.includes(jsonSelectedTiposCeseValue.IdEntity ) ? (
                                <div className="form-group row form-input-modal-contactanos">
                                    <label className="col-sm-4 col-form-label modal-label">Motivo de Renuncia<span className="required">*</span></label>
                                    <div className={jsonSelectedMotivoRenunciaValue?.label === "Otros" ? "col-sm-2" : "col-sm-6"}>
                                        <div className="black-color">
                                            <SelectInput options={motivosDeRenunciaFiltradosOptions} jsonSelectedValue={jsonSelectedMotivoRenunciaValue} setJsonSelectedValue={setJsonSelectedMotivoRenunciaValue} isDisabled={false}
                                                placeholder={"Seleccionar motivo de renuncia"} />
                                        </div>
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdMotivoRenuncia-validation-message`}></p>
                                    </div>
                                    {jsonSelectedMotivoRenunciaValue?.label === "Otros" ? (
                                        <div className="col-sm-4">
                                            <div className="black-color">
                                                <textarea className="form-control" rows="3" name="DescripcionMotivoRenuncia"
                                                    onChange={(e) => setJsonTextAreaOtros(e.target.value)} value={jsonTextAreaOtros ?? ""}
                                                    placeholder="Escriba otro motivo de renuncia voluntaria" maxLength="100"
                                                ></textarea>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Último día de labores<span className="required">*</span></label>
                                <div className="col-sm-6">
                                    <input type="date" min={currentYear + '-' + formatNumber(currentMonth) + '-' + formatNumber(Number(currentDay))} className="form-control" name="FechaCese"
                                           value={formValues.FechaCese ?? ""} onChange={handleChange}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaCese-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">¿Recomendaría al colaborador para un reingreso futuro? <span className="required">*</span></label>
                                <div className="col-sm-6 justify-content-center align-items-center">
                                    <div className="form-check">
                                        <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="RecomiendaReingreso" value="true" checked={formValues.RecomiendaReingreso == "true"} onChange={handleChange}></input>
                                        <label className="form-check-label modal-ceses-label-radio-button">
                                            Sí
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="RecomiendaReingreso" value="false" checked={formValues.RecomiendaReingreso == "false"} onChange={handleChange}></input>
                                        <label className="form-check-label modal-ceses-label-radio-button">
                                            No
                                        </label>
                                    </div>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-RecomiendaReingreso-validation-message`}></p>
                                </div>
                            </div>
                            <InputTextArea isRequired label="¿Por qué?" name="MotivoRecomendacion" onChange={handleChange} value={formValues.MotivoRecomendacion ?? ""} sectionName={sectionName} rows="3" maxLength="1000" placeholder="Máximo 1000 caracteres." colInput={6}/>
                            <InputTextArea label="Observaciones" name="Observaciones" onChange={handleChange} value={formValues.Observaciones ?? ""} sectionName={sectionName} rows="3" maxLength="1000" placeholder="Máximo 1000 caracteres." colInput={6}/>
                            <div>
                                <div className="col-sm-12 black-color">
                                    <GenericUploadFileV2 uploadFileToS3={setS3UploadFileResponseSustento} id={UPDATE_FILE_INPUT_ID} textButton={"Adjuntar Carta"} extensionesValidas={["pdf"]} ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                         subiendoArchivoPublicState={subiendoArchivoSustento} setSubiendoArchivoPublicState={setSubiendoArchivoSustento}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FileViewModel-validation-message`}></p>
                                </div>
                            </div>
                            <div>
                                <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light"> <strong>* El formato permitido es un .pdf de máximo 8MB.</strong></label>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className="modal-footer" style={{'marginTop': '15px'}}>
                        <div className="message font-gothan-light" style={{'width': '500px'}}>
                            <strong>
                                <p>* Campos obligatorios</p>
                            </strong>
                            <strong>
                                <p>* Los documentos adjuntos son evidencia para la validación de la carta de renuncia.</p>
                            </strong>
                        </div>
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha-modal font-gothan-light" onClick={submitModalRegistrarCeseProxy} disabled={subiendoArchivoSustento}>Enviar</button>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    )
};

export default RegistrarCeseModal;
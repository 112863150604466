import React, { useState, useEffect } from 'react'
import GenericModal from '../../../../components/modal/GenericModal';
import { getHijosPorIdSolicitudDescuentoPensionAPI} from '../../../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';

const VerDetalleModal = ({ toggleModal, IdSolicitudDescuentoPension }) => {

  const [formValues, setFormValues] = useState([]);
  const [executePost] = useManagePostRequest();

  const init = async () => {
    const api = getHijosPorIdSolicitudDescuentoPensionAPI();
    const payload = { IdSolicitudDescuentoPension };
    await executePost(api, payload, ({ data }) => setFormValues(data));
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <GenericModal showModal={toggleModal} titulo='Ver Detalle' width="8 00px">
      <div className="container-fluid body-content">
        {/* <label className="col-form-label">Tiene Asignación Familiar: <strong>{formValues?.TieneAsignacionFamiliar}</strong></label> */}
        <div className="table-scroll pt-3">
          <table className="table table-hover">
            <thead className="listar-thead">
              <tr className="font-gothan-medium">
                <th className='text-center col-sm-2'>DNI</th>
                <th className='text-center col-sm-4'>Nombre</th>
                <th className='text-center col-sm-3'>Colegio</th>
                <th className='text-center col-sm-1'>Edad</th>
                <th className='text-center col-sm-1'>
                  <div className='text-wrap'>Registrado en Ficha</div>
                </th>
                <th className='text-center col-sm-1'>Matriculado</th>
                <th className='text-center col-sm-1'>Estado del Familiar</th>
                <th className='text-center col-sm-1'>Descuento</th>
              </tr>
            </thead>
            <tbody>
              {formValues?.Hijos?.map((o, i) => (
                <tr key={i}>
                  <td className='text-center'>{o.NumeroDocumentoHijo}</td>
                  <td className=''>{o.NombreHijo}</td>
                  <td className=''>{o.Colegio}</td>
                  <td className='text-center'>{o.EdadHijo}</td>
                  <td className='text-center'>{o.RegistradoEnFicha ? "Sí" : "No"}</td>
                  <td className='text-center'>{o.Matriculado ? "Sí" : "No"}</td>
                  <td className='text-center'>{o.Validado}</td>
                  <td className='text-center'>{o.Descuento}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </GenericModal>
  )
}

export default VerDetalleModal
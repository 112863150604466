import React, {useState, useEffect} from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { getInfoColaboradorNuevoActivityAPI, confirmarAccesoJiraActivityAPI } from "../../../consumers/backendApisUrls";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useForm } from "../../../hooks/useForm/UseForm";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";

const ConfirmarAccesoJira = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {

  const SECTION_NAME = "ConfirmarAccesoJira";
  const KEYS = ["AccesoJira"];

  const [dataEmpleado, setDataEmpleado] = useState({});
  const [formValues, setFormValues] = useState({AccesoJira: false});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleChange] = useForm(formValues, setFormValues);

  const [executeGet] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  const init = async () => {
    const api = getInfoColaboradorNuevoActivityAPI(IdEmpleadoFlujoActividad);
    await executeGet(api, sucessInitCallback);
  };

  const sucessInitCallback = (res) => {
    let data = res.data;
    setDataEmpleado(data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = confirmarAccesoJiraActivityAPI();
      let payload = getPayload();

      await submitForm(KEYS, api, payload, SECTION_NAME, successSubmitCallback, true);

    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const getPayload = () => {
    return {
      IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
      AccesoJira: formValues?.AccesoJira,
    };
  };

  const successSubmitCallback = () => {
      showModal();
      manageSearch();
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) init();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <GenericModal
      showModal={showModal}
      width={"600px"}
      titulo="Confirmar acceso a GLPI"
    >
      <div className="modal-body pt-0 pl-0 pb-0">
        <form onSubmit={handleSubmit}>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Colaborador
            </label>
            <label className="col-sm-7 col-form-label black-color">
              {dataEmpleado?.NombreCompleto}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Correo
            </label>
            <label className="col-sm-7 col-form-label black-color">
              {dataEmpleado?.CorreoCorporativo}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              DNI
            </label>
            <label className="col-sm-7 col-form-label black-color">
              {dataEmpleado?.NumeroDocumento}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Cargo
            </label>
            <label className="col-sm-7 col-form-label black-color">
              {dataEmpleado?.Cargo}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Sede
            </label>
            <label className="col-sm-7 col-form-label black-color">
              {dataEmpleado?.Sede}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Fecha Ingreso Empresa
            </label>
            <label className="col-sm-7 col-form-label black-color">
              {dataEmpleado?.FechaIngresoEmpresa}
            </label>
          </div>
          <div className="form-group row mb-4">
            <label className="col-sm-5 col-form-label modal-label" htmlFor="asistenciaFalse">
              Acceso Jira
            </label>
            <div className="col-sm-7 d-flex flex-column justify-content-center align-items-start" style={{marginTop: "10px"}}>
              <input
                type="checkbox"
                id="asistenciaFalse"
                className="ingreso-input-checkbox"
                name="AccesoJira"
                onChange={handleChange}
                checked={formValues?.AccesoJira}
              />  
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${SECTION_NAME}-AccesoJira-validation-message`}
              ></p>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="generic-button-buscador"
              disabled={isSubmitting}
            >
              Completar
            </button>
          </div>
        </form>
      </div>
    </GenericModal>
  )
}

export default ConfirmarAccesoJira
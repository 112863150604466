import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../hooks/context/authContext';
import { useHistory } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import TableMaternidad from './TableMaternidad'
import { ROLES } from '../../constants/hardCodedConstants';
import PageHeader from '../../components/pageHeader/PageHeader';
import PageWrapper from '../../components/pageComponents/PageWrapper';
import PageButtons from '../../components/pageComponents/PageButtons';
import ModalEnabled from '../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../components/pageComponents/SearchContainer';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import { initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData, usuarioLogueadoTieneAlgunoDeLosSiguientesRoles } from '../../helper/utils';
import { descargarExcelMaternidadAPI, getAllCargosAPI, getAllEstadosAPI, getAllSedesAPI, getListaCondicionAPI, getMotivoTerminoMaternidadAPI, getAllTipoCondicionAPI, getAllDepartamentosAPI } from '../../consumers/backendApisUrls';
import CrearMaternidad from './modales/CrearMaternidad';

const INIT_KEYS = {
  departamentos: "IdsDepartamentos",
  cargos: "IdsCargos",
  sedes: "IdsSedes",
  
  estadoEmpleado: "IdsEstadosEmpleado",
  
  estadosAprobacion: "IdsEstadosAprobacion",
  estadoProceso: "IdsEstadosProceso",
  
  condicion: "IdsTipoDeCondicion",
  motivo: "IdsMotivoTerminoMaternidad",

  preExistencia: "IdsPreExistencia",
  subsidio: "IdsTieneSubsidio",
};

const GestionMaternidad = () => {

  const { state } = useContext(AuthContext);
  const history = useHistory();
  const isAdmin = usuarioLogueadoTieneAlgunoDeLosSiguientesRoles(state.roleIds, [ROLES.SuperAdmin.id, ROLES.Compensaciones.id, ROLES.Bienestar.id]);

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [downloaAndDeletedReport] = useGenerarDescargarEliminarArchivo();

  const initialPromises = () => {
    const response = executeGet(getAllEstadosAPI());

    return [
      executeGet(getAllDepartamentosAPI()),
      executeGet(getAllCargosAPI()),
      executeGet(getAllSedesAPI()),
      response,
      response,
      response,
      executeGet(getAllTipoCondicionAPI()),
      executeGet(getMotivoTerminoMaternidadAPI()),
      response,
      response,
    ];
  }

  const init = async () => {
    setIsLoading(true);
    try {
      await initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await manageSearch();
      if (formValues.filters.length === 0) {
        jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleUpdatedFilters);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = getListaCondicionAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleDescargar = async () => {
    const api = descargarExcelMaternidadAPI();
    const payload = getPayload();
    await downloaAndDeletedReport(api, payload);
  }

  const handleUpdatedFilters = (res) => {
    setFormValues(lastData => ({ ...lastData, filters: res }))
  }

  const handleCleanSearch = () => {
    setFormValues({ ...formValues, selectedFilters: null });
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleUpdatedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  const PageButtonsComponent = (
    <PageButtons>
      <NavDropdown
        className="generic-button-dropdown"
        title={<span className="generic-button-dropdown-title">Acciones</span>}>

        {formValues?.ExtraData?.PuedeCrearCondicion === true && 
        <NavDropdown.Item onClick={() =>
          setModalEnabled({ isEnable: true, component: CrearMaternidad, data: { isAdmin: false, idEmpleado: 0} })
         }>Crear Nuevo
        </NavDropdown.Item>
        }
        

        <NavDropdown.Item
          onClick={() => history.push("/registroCondicion/asistenciaLactario")}>
          Asistencia Lactario
        </NavDropdown.Item>
        <NavDropdown.Item onClick={handleDescargar}>Descargar</NavDropdown.Item>
        
      </NavDropdown>
    </PageButtons>
  );

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled
        modalEnabled={modalEnabled}
        setModalEnabled={setModalEnabled}
        refreshPage={manageSearch}
      />
      <PageHeader title="Listado de registros de DM y/o maternidad" />
      <SearchContainer
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        setPaginator={setPaginator}
        manageSearch={manageSearch}
        addCleanSearch={handleCleanSearch}>
        {PageButtonsComponent}
      </SearchContainer>
      <TableMaternidad
        formValues={formValues}
        setFormValues={setFormValues}
        extraData={formValues.ExtraData}
        setModalEnabled={setModalEnabled}
        paginator={paginator}
        setPaginator={setPaginator}
        keys={INIT_KEYS}
        isAdmin={isAdmin}
      />
    </PageWrapper>
  );
}

export default GestionMaternidad